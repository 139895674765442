.chat-message {
    padding: 1em;
    border-radius: .4em;
}

.chat-message-user {
    background-color: #f0f0f0;
    text-align: right;
}

.chat-message-system {
    background-color: #e2e9cb;
}

.chat-title {
    margin-bottom: 0.5em;
}

.chat-title-text {
    font-weight: bold;
    font-size: 110%;
}

.chat-title-user {
    text-align: right;
}