.quicksearch-results {
    width: 100%;
    border-collapse: collapse;
}

.quicksearch-result {
    padding: 1em;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

.quicksearch-result:last-child {
    border-bottom: none;
}

.quicksearch-result td {
    padding: 0.5em;
    vertical-align: middle;
}

.quicksearch-result-icon {
    font-size: 200%;
    width: 25px;
    text-align: center;
}

.home-search-bar .ms-Callout {
    width: 100%;
}

.quicksearch-result.selected {
    background-color: #f5f5f5;
}