.navbar-text {
    padding:8px;
}
.octicon {
    margin:0 5px;
}

.mainLogo {
    height: 40px;
}

.navbar {
    border-bottom: 1px solid #080808;
}

.navbar-brand {
    padding:0;
}