@import url(https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic&subset=latin);

#fluent-default-layer-host {
  z-index: 100 !important;
}


html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 14px;
}

body {
  margin: 0;
  overflow-x: hidden;
  min-width: 320px;
  background: #fff;
  font-size: 14px;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, .87);
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

body ::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, .25);
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
}

body ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, .1);
  border-radius: 0;
}

h2 {
  font-size: 1.71428571rem;
}


::selection {
  background-color: #cce2ff;
  color: rgba(0, 0, 0, .87);
}

body, h1, h2, h3, h4, h5 {
  padding: 0;
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child {
  margin-top: 0;
}

h1 {
  min-height: 1rem;
  font-size: 2rem;
}

h1, h2, h3, h4, h5 {
  line-height: 1.28571429em;
  margin: calc(2rem - 0.14286em) 0 1rem;
  font-weight: 700;
}

.search-content-full,
.search-content-reduced {
  float: left;
  padding: 1em;
  width: 100%;
  height: 100%;
}
.search-content-reduced {
  padding-left: 21em;
}

.content {
  padding: 1em;
}

.ui.top.fixed.menu {
  z-index: 100;
}

.search-panel {
  z-index: 10;
}

.separator{
  background-color: rgb(226, 233, 203);
}

.separator::before {
  background-color: rgb(194, 194, 194);
  height: 1px;
  content: "";
  display: block;
  position: absolute;
  inset: 50% 0 0;
}

.accordion-title{
  font-weight: bold;
  font-size: 1rem;
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
  padding: 0.5em 0;
}

.btn-warning {
  background-color: #D0342C;
  color: #FFFFFF;
}
.btn-warning:hover {
  background-color: #bf2e26;
  color: #FFFFFF;
}

.search-sidebar-open {
  width: 20em;
  float: left;
  padding: 1em 1em 1em 1em;
  background-color: #e2e9cb;
  min-height: 100vh;
  overflow: auto;
  height: 100%;
  position: fixed;
}
.search-sidebar-open {
  display: block;
}
.search-sidebar-closed {
  display: none;
}
@media (max-width: 767px) {
  .search-sidebar-open {
    position: absolute;
    width: 100%;
    float: none;
  }
  .search-content-reduced {
    display: none;
  }
  .composer-selector-cell-hidden {
    display: block;
  }
}
@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  body {
    padding-top: 50px;
  }
}

.row {
  display: flex;
}

.column {
  flex: 50%;
  padding: 10px;
}

.xl-modal {
  min-width: 80%;
}


.search-results-row,
.extensions-row {
  margin-bottom: 30px;
}


.container {
  max-width: 1800px;
  width: 90%;
  /* min-width: 800px; */
}

.grey {
  color: lightgray;
}
.react-autosuggest__container {
  position: relative;
  max-height: 300px;
}

.react-autosuggest__input {
  width: 100%;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

#LoginContainer {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

#LoginCard {
  width: 400px;
  height: 300px;
  margin: 0 auto;
}

.text-center {
  text-align: center !important;
  align-content: center;
}

.text-right {
  align-content: end;
  text-align: right !important;
}

.nav-dropdown-link {
  color: #007bff;
}

.ui.form .fields {
  margin-left:0 !important;
}

.viewButtons {
  text-align: right;
}

.product-image-edit {
  border: 3px solid transparent;
}
.product-image {
  padding-left: 15px;
}
.product-image-edit:hover {
  cursor: pointer;
  border: 3px solid rgb(120, 156, 47);
  border-radius: 6px;
  overflow: hidden;
  background-color: #e2e9cb;
}

.filterbutton{
  cursor: pointer;
  display: block;
  min-height: 1em;
  outline: none;
  border: none;
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, .6);
  font-size: 1rem;
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
  margin: 1em .25em 0 0;
  padding: .78571429em 1.5em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: .28571429rem;
  -webkit-user-select: none;
  user-select: none;
  transition: opacity .1s ease, background-color .1s ease, color .1s ease, box-shadow .1s ease, background .1s ease;
  width: 100%;
}

.filterbutton:hover {
  background-color: #cacbcd;
  background-image: none;
  color: rgba(0, 0, 0, .8);
}

.search-bar-group .react-autosuggest__container {
  flex: 1 1 auto;
  width: 1%;
}

.search-bar,
.search-bar-form {
  /* background-color:#789c2f; */
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.search-criteria {
  padding: 10px;
  height: 100%;
  display: flex;
  flex-flow: column;
  min-height: calc(100vh - 110px);
}

.search-criteria-col {
  background-color: #e2e9cb;
  background-clip: border-box;
}

.all-content {
  margin-top: 48px;
}
.main-content {
  padding: 1em;
}

.ac-header {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

h5 {
  margin-top: 2em;
  margin-bottom:0.5em !important;
}

.search-criteria:after {
  background-color: #e2e9cb;
  left: -999em;
  content: '';
  display: block;
  position: absolute;
  width: 999em;
  top: 0;
  bottom: 0;
}

.display-options .btn {
  font-size: 16px;
  padding: 1px 8px 2px 8px;
}

.react-icons {
  vertical-align: middle;
}

.star {
  color: #ffd700;
  font-size: 110%;
}

.star-outline {
  color: #999;
}

.main-logo {
  height: 40px;
}

@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  body {
    padding-top: 50px;
  }
}

.xl-modal {
  min-width:80%;
}

.search-results-row,
.extensions-row {
  margin-bottom:30px;
}

.container {
  max-width: 1800px;
  width: 90%;
  /* min-width:800px; */
}

.grey {
  color: lightgray;
}
.react-autosuggest__section-title {
  padding: 10px;
}
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  padding: 10px;
  display: block;
  position: absolute;
  top: 51px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow-y: auto;
  max-height: 250px;
  min-width: 100%;
  z-index: 11;
}
.filtered-field-container {
  border: 4px solid rgb(120, 156, 47);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px;
  margin-top: 1em;
}
.filtered-field{
  padding-left: 30px;
}
.react-autosuggest__section-container {
  border-left: 1px;
  border-color: #007bff;
  display: inline-grid;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 10px;
}

#LoginContainer {
  min-height: 100vh;
  display:flex;
  align-items: center;
}

.text-center {
  align-content: center;
}

.nav-dropdown-link {
  color: #007bff;
}

.viewButtons {
  text-align: right;
}

.search-bar-group .react-autosuggest__container {
  flex: 1 1 auto;
  width: 1%;
}
.search-bar, .search-bar-form {
  background-color:#789c2f;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}
.search-criteria {
  padding:10px;
  height: 100%;
  display:flex;
  flex-flow: column;
  min-height:calc(100vh - 110px);
}

.search-criteria-col {
  background-color: #e2e9cb;
  background-clip: border-box;
}

.btn-primary {
  background-color:#789c2f !important;
  border-color:#666 !important;
}

.btn-primary:hover {
  background-color:#cfdcb5 !important;
  color: #789c2f !important;
  border-color:#789c2f !important;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  background-color:#fff !important;
  border-color:#666 !important;
  color: #789c2f !important;
}
.search-criteria:after {
  background-color:#e2e9cb;
  left: -999em;
  content: '';
  display: block;
  position: absolute;
  width: 999em;
  top: 0;
  bottom: 0;
}

.display-options .btn{
  font-size:16px;
  padding:1px 8px 2px 8px;
}
.icon-button-label {
  font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  color: rgb(50, 49, 48);
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  display: inline-block;
  padding: 5px 0;
  overflow-wrap: break-word;
}
.react-icons {
  vertical-align: middle;
}

.star {
  color:#ffd700;
  font-size:110%;
}

.star-outline {
  color:#999;
}

.search-results {
  margin-top:1em;
}

.ac-icon-container {
  padding: 1em 0 !important;
  width: 90px !important;
  height:90px !important;
  margin: auto;
}

.ac-icon-container-circle {
  border-radius: 50%;
  line-height: 2 !important;
  box-shadow: inset 0 0 0 .2em rgba(0, 0, 0, .1);
  width: 90px !important;
  margin: auto;
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

.ac-icon {
  font-size: 40px;
  height: 80px;
  width: 80px;
}
.ac-item-icon {
  padding-left: 8px;
  padding-right: 30px;
  font-size: 18px;
  height: 20px;
  width: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ac-search-filter-icon {
  font-size: 15px;
  cursor: pointer;
  margin-left: 0.5em;
  opacity: .5;
  transition: background .1s ease;
  display: flex;
  justify-content: center;
}

.ac-search-filter-icon:hover {
  color: #000000;
}

.ac-icon-large {
  padding: .2em 0 !important;
  font-size: 80px;
  height: 80px;
  width: 80px;
}

.ac-icon-reverse {
  font-size: 40px;
  height: 80px;
  width: 80px;
  transform: scaleX(-1);
}

.add-product-image-card {
  display: inline-block;
  margin-right: 20px;
  width: 250px;
  height: 400px;
  padding: 10px;
  max-width: 320px;
  min-width: 206px;
  position: relative;
  user-select: none;
  border: 4px dashed #cacbcd;
  border-radius: 8px;
}

.add-product-image-card:hover .ac-product-image-add-icon {
  color: rgb(120, 156, 47);
  padding-left: 60px;
  font-size: 126px;
  height: 80px;
  width: 80px;
}

.add-product-image-card:hover {
  cursor: pointer;
  border: 4px dashed rgb(120, 156, 47);
  border-radius: 8px;
}

.ac-product-image-add-icon {
  color: rgb(244, 244, 244);
  padding-left: 60px;
  font-size: 126px;
  line-height: normal;
}

.ac-dimmer {
  display: flex !important;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 1em;
  background-color: hsla(0, 0%, 100%, .85);
  line-height: 1;
  animation-fill-mode: both;
  animation-duration: .5s;
  transition: background-color .5s linear;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
  will-change: opacity;
  z-index: 9998;
  visibility: visible !important;
}

.ac-button {
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: none;
  border: none;
  vertical-align: baseline;
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, .6);
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
  margin: 0 0.25em 0 0;
  padding: 0.78571429em 1.5em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  -webkit-user-select: none;
  user-select: none;
  transition: opacity .1s ease, background-color .1s ease, color .1s ease, box-shadow .1s ease, background .1s ease;
  -webkit-tap-highlight-color: transparent;
  font-size: 1rem;
}

.ac-button:hover {
  background-color: #c9c9c9;
}
*, :after, :before {
  box-sizing: inherit;
}

.ac-section {
  display: block;
}

.ac-segment {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  max-width: none;
  animation: none;
  overflow: visible;
  min-height: 18rem;
  background: #f9fafb;
  box-shadow: inset 0 2px 25px 0 rgb(34 36 38 / 5%);
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, .15);
}
.ac-attribute-segment {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  max-width: none;
  animation: none;
  overflow: visible;
  background: #f9fafb;
  box-shadow: inset 0 2px 25px 0 rgb(34 36 38 / 5%);
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, .15);
}
.ac-attribute-section {
  display: flex;
  width: 100%;
  height: 30px;
  margin: auto;
  box-shadow: inset 0 2px 25px 0 rgb(34 36 38 / 5%);
  align-items:center;
  justify-content:center;
  font-size: 18px;
  font-weight: 600;
}
.ac-attribute-container::-webkit-scrollbar{
  display: none;
}
.ac-segment-button-bottom {
  border-radius: 0 0 0.28571429rem 0.28571429rem;
  display: block;
  width: 100%;
  box-shadow: 0 0 0 1px rgb(34 36 38 / 15%) !important;
  position: relative;
  margin: 0;
}

.ac-segment:last-child {
  margin-bottom: 0;
}

.ac-segment:first-child {
  margin-top: 0;
}

.ac-dimmer-content{
  opacity: 1;
  z-index: 9999;
  user-select: text;
  color: #fff;
  visibility: visible !important;
}

.procurement-scroll-container{
  margin: 4px;
  padding: 4px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.ac-grid-field{
  font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none;
  margin: 0;
  padding: 6px 8px;
  box-sizing: border-box;
  border-radius: 0;
  border: none rgb(243, 242, 241);
  width: 100%;
  min-width: 0;
  text-overflow: ellipsis;
  outline: 0;
  display: inline-flex;
}
.ms-Pivot-icon{
  color: red;
}

.ac-details-field{
  font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none;
  margin: 0;
  padding: 8px 8px 8px 8px;
  box-sizing: border-box;
  border-radius: 0;
  border: none rgb(243, 242, 241);
  width: 300px;
  text-overflow: ellipsis;
  outline: 0;
  display: block;
}

.ac-search-filter {
  height: 30px;
  width: fit-content;
  margin-bottom: 0;
  max-width: 9999px;
  vertical-align: baseline;
  text-transform: none;
  background: #e8e8e8;
  padding: 0.25em 0.75em 0.25em 0.75em;
  border-radius: 0.28571429rem;
  box-shadow: none;
  margin-top: 1em;
  display: inline-flex;
  font-size: .85714286rem;
  font-weight: 700;
  line-height: 1;
  color: rgba(0, 0, 0, .6);
  transition: background .1s ease;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 4px;
}
.ac-search-filter:last-child {
  margin-right: 0;
}
.ac-search-filter:first-child {
  margin-left: 0;
}

.ac-card-buttons {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 1px;
}

.paginator-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100; 
  background-color: white;

}

.paginator-buffer {
    height: 60px;
}

.ac-pagination-menu {
  margin: 0;
  display: inline-flex;
  vertical-align: middle;
  align-content: center;
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
  background: #fff;
  font-weight: 400;
  box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
  border-radius: 0.28571429rem;
  min-height: 1em;
}

.ac-pagination-menu:last-child {
  margin-bottom: 0;
}
.ac-pagination-menu-container {
  align-content: center;
  width: 100%;
  margin: auto;
}

.ac-pagination-item {
  display: inline-flex;
  min-width: 1em;
  text-align: center;
  position: relative;
  vertical-align: middle;
  line-height: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  flex: 0 0 auto;
  -webkit-user-select: none;
  user-select: none;
  background: none;
  padding: 0.92857143em 1.14285714em;
  text-transform: none;
  color: rgba(0, 0, 0, .87);
  font-weight: 400;
  transition: background .1s ease, box-shadow .1s ease, color .1s ease;
}
.ac-pagination-item:hover {
  background-color: #c9c9c9;

}
.ac-pagination-item-active{
  background-color: rgb(120, 156, 47);
  color: white;
  border-width: 2px;
}

.ac-pagination-menu-item:first-child {
  border-radius: 0.28571429rem 0 0 0.28571429rem;
}

.ac-attribute-dropdown-item {
  border-width: unset;
  border-style: unset;
  border-color: unset;
  border-radius: unset;
  background-color: unset;
}

.ms-Callout {
  width: auto;
  min-width: 150px;
}

.label {
  font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  color: rgb(50, 49, 48);
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  display: block;
  padding: 5px 0;
  overflow-wrap: break-word;
}

.labeled-content {
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none;
  margin: 0;
  padding: 0 8px;
  box-sizing: border-box;
  border-radius: 0;
  border: none;
  background: none transparent;
  color: rgb(50, 49, 48);
  width: 100%;
  min-width: 0;
  text-overflow: ellipsis;
  outline: 0;
}

.labeled-content-container {
  padding-top: 30px;
}

@-webkit-keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.ui.cards > .card > .card-header-content,
.ui.card > .card-header-content {
  background-color: #eee;
}

.italic-placeholder input::placeholder {
  font-style: italic;
  color: #888;
}

.no-stock-button {
  background-color: #ddd;
  border: 0;
  color: black;
}

.no-stock-button:hover {
  background-color: #ccc;
  color: black;
  border: 0;
}
.no-stock-button:active {
  background-color: #bbb;
  color: black;
  border: 0;
}

.no-stock-button .stock-data, .no-stock-button .stock-data-only {
  color: red;
}

.stock-data {
  font-size: 80%;
}

.requirementdesciption {
  color: rgb(96, 94, 92);
  font-size: 10px;
}

.in-stock-dense {
    padding: 0.5em;
    text-align: center;
}

@keyframes placeHolderShimmer {
  0%   { background-position:  100%; }
  100% { background-position: -100%; }
}

.ms-DetailsRow-cell:has(>.vendor-loading-in-stock-cell) {
  animation: 3s linear infinite placeHolderShimmer;
  background: linear-gradient(to right, #c8ceb5 0%, #e2e9cb 50%, #c8ceb5 100%);
  background-size: 200%;
  height: 100%; width: 100%;
}
.ms-DetailsRow-cell:has(>.vendor-loading-no-stock-cell) {
  animation: 3s linear infinite placeHolderShimmer;
  background: linear-gradient(to right, #eee 0%, #ccc 50%, #eee 100%);
  background-size: 200%;
  height: 100%; width: 100%;
}

.vendor-loading-in-stock-button {
  animation: 3s linear infinite placeHolderShimmer;
  background: linear-gradient(to right, #eee 0%, #e2e9cb 50%, #eee 100%);
  background-size: 200%;
  border: 0;
  color:black;
}

.vendor-loading-no-stock-button {
  animation: 3s linear infinite placeHolderShimmer;
  background: linear-gradient(to right, #eee 0%, #ccc 50%, #eee 100%);
  background-size: 200%;
  height: 100%; width: 100%;
  color:black;
  border: 0;
}

.ms-DetailsRow-cell:has(>.in-stock-dense) {
  background-color: #e2e9cb;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-stock-dense {
  padding: 0.5em;
  text-align: center;
}

.vendor-dense {
    cursor: pointer;
}

.ms-DetailsRow-cell:has(>.no-stock-dense) {
  background-color: #ddd;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.embedded-login-container{
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: table-cell;
  vertical-align: middle;
  background: #e2e9cb;
  z-index: 0;
  
}
.embedded-login {
  width: 286px;
  height: 368px;
  padding:60px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-style: solid;
  border-radius: 10px;
  border-color: #e2e9cb;
  background-color: white;
}
.cost-alert {
  font-size: 90%;
  color: #789C2FFF;
}

.cost-alert-stock {
  font-size: 90%;
  color: #e2e9cb;
}

.clear-fix {
    clear: both;
}


.vendor-selection-table {
  border-spacing: 0;
}

.vendor-selection-table>thead>tr>th:nth-child(1) {
  padding: 0.5em 0.5em 0.5em 0;
  text-align: left;
}

.vendor-selection-table>thead>tr>th {
  padding: 0.5em;
}

.vendor-selection-table>tbody>tr>td {
  padding: 0.5em;
}

.vendor-selection-table>tbody>tr:nth-child(odd) {
  background-color: #eee;
}

.vendor-selection-table>tbody>tr:hover {
  background-color: #e7e7e7;
}

.vendor-selection-table>tbody>tr:nth-child(odd):hover {
  background-color: #ddd;
}

.vendor-selection-checkbox {
  border-radius: 100%;
}

.radio-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #000;
  cursor: pointer;
  margin: 0 auto;
}

.radio-button-checked::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(120, 156, 47);
  display: block;
  margin: 4px;
}

.radio-button-checked {
  border-color: rgb(120, 156, 47);
}

.radio-button:hover {
  border-color: #666;
}

.radio-button-unchecked::after {
  content: "";
  width: 0;
  height: 0;
  border-radius: 50%;
  display: block;
  margin: 4px;
}

.radio-button-checked:hover::after,
.radio-button-unchecked:hover::after {
  width: 10px;
  height: 10px;
  background-color: #666;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th {
  color: #000;
  padding: 8px;
  text-align: left;
  border-bottom: 2px solid rgb(207, 220, 181)	;
}

.data-table td {
  padding: 8px;
  text-align: left;
}

.data-table>tbody>tr {
  border-bottom: 1px solid rgb(207, 220, 181);
}

.data-table>tbody>tr:last-child {
  border-bottom: none;
}

.data-table>tbody>tr:hover {
  background-color: #eee;
}
.e-rte-table img {
  max-width: 100%;
}

.field-error-message {
  animation-name: css-0, css-13;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-weight: 400;
  color: rgb(164, 38, 44);
  margin: 0;
  padding-top: 5px;
  display: flex;
  align-items: center;
}

.list-table {
  width: 100%;
  border-collapse: collapse;
}

.list-table th {
  color: #000;
  padding: 1em 0;
  text-align: left;
  border-bottom: 1px solid #bbb;
}

.list-table>tbody>tr>td {
  padding: 1em 0.5em;
}

.list-table>tbody>tr {
  border-bottom: 1px solid #bbb;
}

.list-table>tbody>tr:last-child {
  border-bottom: none;
}

.list-table>tbody>tr:hover {
  background-color: rgb(240, 240, 240);
}

.bundle-image {
  width: 40%;
  height: 78px;   
  background-color: #fff;   
  border-radius: 2px;   
  border: 1px solid #ccc;   
  margin: 5px;   
  padding: 5px;   
  color: #000;   
  display: inline-flex;   
  cursor: pointer;
}

.bundle-image-selected {
  border: 3px solid rgb(120, 156, 47);
  border-radius: 6px;
  overflow: hidden;
  background-color: #e2e9cb;
  width: 40%;
  height: 78px;
  margin: 5px;
  padding: 5px;
  color: #000;
  display: inline-flex;
  cursor: pointer;
}

.bundle-image-pod {
  height: 15em;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 5px #ccc;
  margin: auto;
  overflow-y: scroll;
}

.powered-by-footer-fixed { /*for pages w/out scroll*/
  bottom: 0;
  position: fixed;
  float: left;
  padding-top: 25px;
  padding-bottom: 10px;
  color: grey;
}

.powered-by-footer-absolute { /*for pages with scroll, this can sometimes add scroll to a page that would otherwise not have it*/
  bottom: 0;
  align-self: flex-end;
  float: left;
  padding-top: 25px;
  padding-bottom: 10px;
  color: grey;
}
.powered-by-footer-settings-page {
  padding-left: 15px;
  bottom: 0;
  align-self: flex-end;
  float: left;
  padding-top: 25px;
  padding-bottom: 10px;
  color: grey;
}

.composer-header {
  outline: transparent;
  position: relative;
  color: rgb(50, 49, 48);
  box-sizing: border-box;
  padding: 0px 32px 0px 12px;
  line-height: inherit;
  margin: 0px;
  height: 42px;
  vertical-align: top;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  flex: 0 1 auto;
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
  border: 0;
  border-bottom: 1px solid rgb(207, 220, 181);
}

.composer-header:hover {
  background-color: rgb(240, 240, 240);
}

.composer-cell {
  outline: transparent;
  position: relative;
  min-height: 42px;
  vertical-align: top;
  white-space: normal;
  overflow: hidden;
  text-overflow: clip;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 12px;
  word-break: break-word;
}

.composer-row:hover {
  background-color: rgba(0, 0, 0, .1);
  border: 0;
}

.composer-row-selected{
  color: rgb(32, 31, 30);
  font-size: 14px;
  font-weight: 600;
  background-color: rgb(207, 220, 181);
  border: 0;
}
.composer-selector-cell-hidden {
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  left: 10px;
  top: -7px;  
  width: 18px;
  height: 18px;
  text-align: center;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  display: none;
  color: #789c2f;
}

.composer-selector-cell-hidden:hover {
  display: block;
}

.composer-selector-cell:hover + .composer-selector-cell-hidden {
  display: block;
}


.composer-selector-all-header-hidden {
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  left: 10px;
  top: 0px;
  width: 18px;
  height: 18px;
  text-align: center;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  display: none;
  color: #789c2f;
}

.composer-selector-all-header-hidden:hover {
  display: block;
}

.composer-selector-cell:hover + .composer-selector-all-header-hidden {
  display: block;
}
.composer-selector-all-header-label{
  padding-left: 5px;
  outline: transparent;
  position: relative;
  color: rgb(50, 49, 48);
  box-sizing: border-box;
  line-height: inherit;
  height: 42px;
  vertical-align: top;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
  display: inline-flex;
}

.composer-header-main{
  border-bottom: 1px solid #ccc;
}

.composer-header-main:hover + .composer-selector-all-header-hidden {
  display: block;
}

.resize-handle {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 7px;
  right: 0;
  top: 0;
  z-index: 1;
  border-right: 2px solid transparent;
}

.resize-handle:hover {
  border-color: #ccc;
}

.resize-handle.active {
  border-color: #517ea5;
}

.home-content {
  max-width: 1000px;
  width: 80%;
  margin: 20vh auto;
}


.home-list-group {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3em;
}

.home-list {
  padding: 0 1em;
  width: calc(33% - 2em);
}

.home-list h3 {
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.home-list table {
  width: 100%;
  border-collapse: collapse;
}

.home-list table tr {
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.home-list table tr:last-child {
  border-bottom: none;
}

.home-list table tr:hover {
  background-color: #f9fafb;
}

.home-list table td {
  padding: 0.5em;
}

@media (max-width: 1300px) {
  .home-content {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .home-content {
    width: 95%;
    padding: 1em;
    margin: 0 auto;
  }

  .home-list-group {
    display: block;
    flex-wrap: nowrap;
    width: 95%;
    margin: 0 auto;
    margin-top: 1em;
    padding: 0;
  }

  .home-list {
    width: 100%;
    padding: 0;
    margin-top: 3em;
  }
}
.fixed-footer {
  width: 100%;
  position: sticky; 
  bottom: 0; 
  padding: 1em 0; 
  display: flex; 
  justify-content: center;
}
.fixed-footer-large{
  position: sticky;
  bottom: 0;
  display: flex;
  padding: 1em 0 1em 24px;
  justify-content: left;
}

.home-workspace-title{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  to {
      opacity: 1;
  }
}

.hide {
  display: none;
}