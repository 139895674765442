.result-table th {
    color: #888;
}

.ac-sortable-vendor-header {
    cursor: pointer;
    width: 600px;
    height: 100%;
}

.ac-sortable-column-header {
    cursor: pointer;
    height: 100%;
    float: left;
    padding-right: 1em;
}