#LoginContainer {
	background-color: #e2e9cb;
}
.loginLogo {
	width: 60%;
	margin: 1em auto;
}
.okta-form-title {
	text-align: center;
	color: #555;
	font-size: 1.3em;
	font-weight: bold;
	padding: 0;
	margin: 0.5em;
}
.okta-form-label {
	display: block;
	margin: 0 0 0.28571429rem 0;
	color: rgba(0, 0, 0, 0.87);
	font-size: 0.92857143em;
	font-weight: bold;
	text-transform: none;
}
.custom-checkbox {
	position: relative;
	display: inline-block;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	outline: none;
	vertical-align: baseline;
	font-style: normal;
	min-height: 17px;
	font-size: 1rem;
	line-height: 17px;
	min-width: 17px;
}
.custom-checkbox>label {
	cursor: pointer;
	color: rgba(0, 0, 0, 0.87);
	padding-left: 0.5em;
	font-size: 1em;
}

.okta-form-input-field>input,
#okta-signin-username,
#okta-signin-password,
#account-recovery-username {
	width: 100%;
	max-width: 100%;
	flex: 1 0 auto;
	outline: none;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	text-align: left;
	line-height: 1.21428571em;
	font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	padding: 0.67857143em 1em 0.67857143em 0;
	background: #FFFFFF;
	border: 1px solid rgba(34, 36, 38, 0.15);
	color: rgba(0, 0, 0, 0.87);
	border-radius: 0.28571429rem;
	transition: box-shadow 0.1s ease, border-color 0.1s ease;
	box-shadow: none;
	margin: 0 0 1em;
}

#okta-signin-submit,
.o-form-button-bar>input {
	background-color: #789c2f;
	color: #FFFFFF;
	text-shadow: none;
	background-image: none;
	width: 100%;cursor: pointer;
	display: inline-block;
	min-height: 1em;
	outline: none;
	border: none;
	vertical-align: baseline;
	font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	margin: 1em 0 1em 0;
	padding: 1em 1em 1em 0;
	text-transform: none;
	font-weight: bold;
	line-height: 1em;
	font-style: normal;
	text-align: center;
	text-decoration: none;
	border-radius: 0.28571429rem;
	box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
	-webkit-user-select: none;
	user-select: none;
	transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
	will-change: '';
	-webkit-tap-highlight-color: transparent;
}

#LoginCard {
	background-color: white;
	width:400px;
	min-height: 500px;
	margin:0 auto;
	overflow: auto;
	box-shadow: 0 1px 3px 0 #d4d4d6, 0 0 0 1px #d4d4d5;
}
